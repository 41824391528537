.paper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    margin-top: 64px;
    
}

.form {
    width: '100%', // Fix IE 11 issue.
}

.provider{
    img{
        width: 20px;
        margin-right: 14px;
    }
}
.provider+.provider{
    margin-top:10px;
}

.google{
    margin-top:25px !important;
    background-color: white !important;
}
.fb{
    background-color: #3b5998 !important;
}
.email{
    background-color: #db4437 !important;   
}

.wait-auth{
    margin-top:45%;
}