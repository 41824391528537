.error{
    /* margin-top: 70px !important;*/

    .area{
        margin-top: 5%;
        display: flex;
        align-items: center;
        flex-direction: column;

        svg{
            font-size: 11rem;
        }

        h4{
            text-align: center;
        }
    }
}
