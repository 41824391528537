.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.backdrop{
  z-index:1201 !important;
  color: #fff !important;
}

.hide{
  width: 0;
  height: 0;
  opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.small-avatar img{
  width: 30px;
  height: 30px;
}
.app-formcontrol.win-lose{
  margin-top:20px;
}
.app-formcontrol.win-lose input{
  font-weight: bold;
  font-size: 1.3rem;
}
.win-lose.win input{
  color: #4caf50;   
}
.win-lose.lose input{
  color: #f44336;
}



.success-avatar{
  background-color:#4caf50 !important;
  color: #fafafa !important;
}

.warn-icon{
  color:#ff5722;
}
.Mui-disabled .warn-icon{
  color:inherit;
}

.app-dialog .app-formcontrol fieldset{
  margin-bottom: 0px;
  margin-left:0;
}

.title-dialog-content-text{
  font-weight: bold !important;
  font-size: 1.2rem !important;
}

.app-dialog .icon-area{
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-dialog .icon-area .icon{
  font-size: 5rem;
}
.app-formcontrol.centered{
  text-align: center;
}
.app-dialog .app-formcontrol +.app-formcontrol {
  margin-top: 8px;
}
.app-dialog .youtube-icon{
  color:grey; 
}
.app-dialog .app-dialog-content{
  padding-top:0px;
}

.event .MuiCardActions-root .MuiButton-root{
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
