.app-chip-item + .app-chip-item{
    margin-left :7px;
}

.app-chip-item{
    margin-top:3px;
}

.event-description{
    white-space: break-spaces;
    text-align: justify;
}

p.tags{
    margin-bottom:5px !important;
}

.app-card-content{
    padding-bottom:5px !important;
    .tags{
        margin-bottom:10px;
    }
}

.description.speaker{
    margin-top:10px;
}
