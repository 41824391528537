.register-form{
    padding: 20px;
    margin-bottom: 100px;

     .app-formcontrol.end{
        margin-top:3px;
    }
}

.card-controls{
    margin-top:20px;
}


.app-formcontrol + .app-formcontrol{
    margin-top:20px;
}

.app-formcontrol-btn{
    text-align: center;
}

.app-formcontrol-thumb{
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        max-width: 25vw;
    }
}

.controls{
    margin: 20px 0;
}
.register-content{
    padding:10px;
}

.register-footer{
    background: #4a4a4a6e;
    margin-left: -20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
}

.chips {
    display: 'flex';
    flex-wrap:'wrap';
  }
.chip {
    margin:2px;
}

.select-formControl {
    margin: 10px;
    min-width: 120px;
    max-width: 300px;
  }