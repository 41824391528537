.no-match{
    /* margin-top: 70px !important;*/

    .area{
        margin-top: 30%;
        display: flex;
        align-items: center;
        flex-direction: column;

        svg{
            font-size: 11rem;
        }
    }
}
