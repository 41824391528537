.more{
    padding:10px;
}

.event-card, .more-card{
    margin-bottom: 20px;
}

.area-note{
    display: flex;
    justify-content: center;
    text-align: center;
}