.my-icon{
    max-height: 42px;
    border-radius: 20px;
    
    margin-bottom: -7px;
}

.install-content{
    display: flex;
    align-items: center;
    justify-content: left;
    flex: 1;
}

.install-close{
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.install-icon{
    background: white;
    width: auto;
    padding: 4px 4px 0px 4px;
    border-radius: 9px;
    margin: 0px 13px;

    img{
        width: 30px;
        height: 30px;
        border-radius: 15Px;
    }
}

.ghost-appbar{
    height:70px;
    width: 100%;
}

.install-title{
    font-size: 0.9rem;
}
.install-actions{
    display: flex;
    align-items: center;
    justify-content: center;
}

.install-bar{
    display: flex;
    flex-direction: row;
}
